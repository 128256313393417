<template>
  <div class="qualification-info">
    <div class="qualificationInfo">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <div class="align-items-center justify-content-between">
            <CRow>
              <CCol md="8">
                <h5 class="main-title pb-3">
                  {{
                      isStudent ? "Medical Studies/Training" : isNonClinicAdmin
                      ? "Qualifications"
                      : "Medical Qualification"
                  }}
                  <span class="h6">
                    [{{ SortedQualifications.length || "0" }} record{{
                      SortedQualifications.length > 1 ? "s" : ""
                    }}] [Add Additional Qualifications Below]</span
                  >
                </h5>
              </CCol>
              <CCol md="4">
                <div class="float-right mb-2">
                  <CButton
                    v-if="
                      !isNewQualification &&
                      !edit_Qualification_id &&
                      !getMultiFormEdited['qualification']
                    "
                    class="btn-primary small mr-2"
                    style="height: 28px"
                    @click="showQualificationInfo"
                    v-c-tooltip="'Click to Add new record!'"
                    >Add</CButton
                  >
                  <CButton
                    v-if="
                      isNewQualification &&
                      !edit_Qualification_id &&
                      getMultiFormEdited['qualification']
                    "
                    class="btn-primary small mr-2"
                    style="height: 28px"
                    @click="updateQualificationInfo"
                    v-c-tooltip="'Click to Save record!'"
                    >Save</CButton
                  >
                  <CButton
                    class="btn-primary small mr-1"
                    style="height: 28px"
                    @click="updateQualificationInfo"
                    v-if="edit_Qualification_id"
                    v-c-tooltip="'Click to update the record!'"
                    >Update</CButton
                  >
                  <button
                    name="cancel"
                    class="hand-icon action-button"
                    v-c-tooltip="'Cancel'"
                    v-if="
                      isNewQualification ||
                      edit_Qualification_id ||
                      getMultiFormEdited['qualification']
                    "
                    @click="discard()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </CCol>
            </CRow>
          </div>
          <ValidationObserver ref="qualification" v-slot="{ handleSubmit }">
            <form id="qualification" @submit.prevent="handleSubmit()">
              <fieldset>
                <div v-if="isNewQualification || edit_Qualification_id">
                  <CRow>
                    <CCol lg="6" md="6" sm="12">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_country"
                          class="required col-lg-12 col-md-12"
                          >{{
                            edit_Qualification_id && !is_primary
                              ? "Qualification Obtained In"
                              : edit_Qualification_id && is_primary
                              ? "First / Primary Qualification Obtained In"
                              : "Additional Medical Qualifications Obtained In"
                          }}</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="country_id"
                              :value="profile.country_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['country']
                                  ? options['country']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol lg="6" md="6" sm="12">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_main_qualification_id"
                          class="required col-lg-12 col-md-12"
                          >{{
                            isNonClinicAdmin && !edit_Qualification_id
                              ? "Additional Qualification"
                              : edit_Qualification_id && is_primary
                              ? "First / Primary Qualification"
                              : "Qualification"
                          }}</label
                        >
                        <div class="col infoicon-multi-select">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="main_qualification_id"
                              :value="profile.main_qualification_id"
                              @input="handleChangeSelect"
                              :options="
                                (isStudent ? options['course_qualifications'] : options['main_qualification_id'])
                                  || []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                        <div
                          v-if="isNonClinicAdmin"
                          class="position-relative multi-select-icon-div"
                        >
                          <CIcon
                            name="cil-info"
                            class="hinticon-multi-select"
                            v-c-tooltip="{
                              content:
                                'Add Language Qualifications in Screen 2',
                              appendToBody: true,
                            }"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol
                      md="6"
                      v-if="
                        profile.main_qualification_id &&
                        profile.main_qualification_id.allow_subject_input ==
                          true
                          && !isStudent
                      "
                    >
                      <CRow name="qualification_subject_name" class="mb-3">
                        <label class="col-lg-12 col-md-12"
                          >Qualification Subject</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="description"
                            :value="profile.description"
                            @input="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol v-if="mainQualificationIsOther" lg="6">
                      <CRow name="other_qualification_name" class="row mb-3">
                        <label class="required col-lg-12 col-md-12"
                          >Other Qualification (Level / Subject)</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="other_qualification_name"
                              :value="profile.other_qualification_name"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow
                    v-if="
                      profile.main_qualification_id
                        ? profile.main_qualification_id.by_exam
                        : false
                    "
                  >
                    <CCol md="6"></CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_by_exam"
                          class="required col-lg-12 col-md-12"
                          >Is This By Exam?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <RadioButton
                              name="by_exam"
                              :value="profile.by_exam"
                              :options="
                                options && options['byExam']
                                  ? options['byExam']
                                  : []
                              "
                              @change="handleChangeRadio"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow  v-if="!isStudent">
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_main_qualification_year"
                          class="required col-lg-12 col-md-12"
                          >Qualification Month / Year Awarded</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider name="dob" v-slot="{ errors }">
                            <TextInput
                              hidden
                              name="dob"
                              :value="profile.dob"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            rules="QualifiedDate:@dob|date_validate|required"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="true"
                              name="qualified_date"
                              :value="profile.qualified_date"
                              @change="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol v-if="!isNonClinicAdmin" md="6">
                      <CRow class="row mb-3">
                        <label
                          :class="
                            profile.main_qualification_id
                              ? profile.main_qualification_id
                                  .course_duration_reqd
                                ? 'required'
                                : false
                              : false
                          "
                          name="lbl_course_duration"
                          class="col-lg-12 col-md-12 course_duration"
                        >
                          Course Duration (yrs)
                          <b
                            >Selected -
                            {{
                              profile.course_duration
                                ? profile.course_duration
                                : "0"
                            }}</b
                          >
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <v-slider
                            name="course_duration"
                            :min="0"
                            :max="10"
                            :value="profile.course_duration"
                            @change="handleSlider"
                          ></v-slider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow v-if="!isNonClinicAdmin && !isStudent">
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label name="lbl_issued_by" class="col-lg-12 col-md-12"
                          >Issued By</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="issued_by"
                            :value="profile.issued_by"
                            @input="handleChangeSelect"
                            :options="
                              options && options['issued_by']
                                ? options['issued_by']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol
                      v-if="
                        profile.issued_by && profile.issued_by.code == 'Others'
                      "
                      md="6"
                    >
                      <CRow name="other_issue_authority" class="row mb-3">
                        <label class="required col-lg-12 col-md-12"
                          >Other Issuing Authority</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="other_issue_authority"
                              :value="profile.other_issue_authority"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <!--<CCol md="6">
                    <CRow class="row mb-1">
                      <label
                        name="lbl_distance_learning"
                        class="col-lg-12 col-md-12"
                        >Distance Learning?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <RadioButton
                          name="distance_learning"
                          :value="profile.distance_learning"
                          :options="
                            options && options['distance_learning']
                              ? options['distance_learning']
                              : []
                          "
                          @change="handleChangeRadio"
                        />
                      </div>
                    </CRow>
                  </CCol>-->
                  </CRow>
                  <CRow  v-if="(isNewQualification || edit_Qualification_id) && isStudent">
                    
                  </CRow>
                  <CRow v-if="(isNewQualification || edit_Qualification_id) && isStudent">
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_course_start_date"
                          class="required col-lg-12 col-md-12"
                          >Course Start Date</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                          name="course_start_date"
                          rules="required|date_validate|no_future_date"
                          v-slot="{ errors }"
                        >
                          <DateInput
                            :onlyMonthAndYear="false"
                            name="course_start_date"
                            :value="profile.course_start_date"
                            @input="handleDatePickerChange"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_course_end_date"
                          class="required col-lg-12 col-md-12"
                          >Course Completion Date</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                          rules="date_validate|future_date"
                          v-slot="{ errors }"
                          >
                          <DateInput
                            :onlyMonthAndYear="false"
                            :futureYear="true"
                            :futureYearLimit=10
                            name="predicted_completion_date"
                            :value="profile.predicted_completion_date"
                            @input="handleDatePickerChange"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow name="label_student_course" class="row mb-3">
                        <label class="col-lg-12 col-md-12 required">
                          Is your course?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                          <RadioButton
                              name="full_part_time"
                              :value="profile.full_part_time"
                              :options="(options && options['modeOfCourse']) || []"
                              @change="handleChangeRadio"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow name="label_student_course" class="row mb-3">
                        <label class="col-lg-12 col-md-12">
                          Is Distance/Online</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="student_online_distance_learning"
                            :value="profile.student_online_distance_learning"
                            :options="(options && options['distance_online']) || []"
                            @change="handleChangeRadio"
                            />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label class="col-lg-12 col-md-12 required">
                          Is this course sponsored/funded
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <RadioButton
                              name="is_sponsored"
                              :value="profile.is_sponsored"
                              :options="(options && options['byExam']) || []"
                              @change="handleChangeRadio"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="profile.is_sponsored">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          class="col-lg-12 col-md-12 required"
                          >Sponsorship Start Date</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="date_validate|no_future_date|required"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="false"
                              name="sponsorship_start_date"
                              :value="profile.sponsorship_start_date"
                              @change="handleDatePickerChange"
                              :pastYearLimit="10"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="profile.is_sponsored">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          class="col-lg-12 col-md-12 required"
                          >Sponsorship End Date</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="date_validate|future_date|required"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="false"
                              :futureYear="true"
                              name="sponsorship_end_date"
                              :value="profile.sponsorship_end_date"
                              @change="handleDatePickerChange"
                              :futureYearLimit="10"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CButton
                    v-if="profile.main_qualification_id"
                    type="button"
                    class="btn-primary rounded-0 small"
                    @click="showLinkDocument()"
                  >
                    Link Document
                  </CButton>
                </div>

                <div v-if="SortedQualifications" class="multi-records">
                  <div
                    class="top-border d-flex justify-content-between"
                    v-for="(data, index) in SortedQualifications"
                    :key="index"
                  >
                    <div class="ml-2 mt-1 record">
                      <div class="widget-content-actions hide-large-screen">
                        <CButton
                          class="icon icon-edit mr-2"
                          v-if="data && data.candidate_qualification_id"
                          @click="editQualification(data)"
                        >
                        </CButton>
                        <CButton
                          class="icon mr-1"
                          v-if="data && data.candidate_qualification_id"
                          @click="deleteQualification(data)"
                        >
                          <i class="fas fa-trash-alt" />
                        </CButton>
                      </div>
                      <CRow
                        class="d-flex"
                        style="justify-content: space-between; margin-left: 5px"
                      >
                        <div class="table col col-6">
                          <p class="head">
                            {{
                              data.primary
                                ? "First / Primary Medical Qualification"
                                : "Qualification"
                            }}
                          </p>
                          <p class="data">{{ data | qualificationName }}</p>
                        </div>
                        <div
                          v-if="!isStudent"
                          class="table col"
                          :class="
                            data.qualification &&
                            data.qualification.allow_subject_input
                              ? 'col-3'
                              : 'col-6'
                          "
                        >
                          <p class="head">Country</p>
                          <p class="data">
                            {{ data.country_name }}
                          </p>
                        </div>
                        <div
                         v-else
                          class="table col col-6"
                        >
                          <p class="head">Country</p>
                          <p class="data">
                            {{ data.country_name }}
                          </p>
                        </div>
                        <div
                          class="table col col-6" 
                          v-if="
                            data.qualification &&
                            data.qualification.allow_subject_input && 
                            !isStudent
                          "
                        >
                          <p class="head">Subject</p>
                          <p class="data">
                            {{ data.description }}
                          </p>
                        </div>
                        <div 
                          class="table col"
                          :class="isStudentPhysician ? 'col-6' : 'col-3'">
                          <div v-if="!isStudentPhysician && !isStudentDentist">
                            <p class="head">Month / Year</p>
                            <p class="data">
                              {{
                                formatDate(data.month_awarded, data.year_awarded)
                              }}
                            </p>
                          </div>
                          <div v-else>
                            <p class="head">Course Start Date</p>
                            <p class="data">
                              {{ formatDateMonthYear(data.course_start_date) || '--'  }}
                            </p>
                          </div>
                        </div>
                        <div 
                          v-if="!isNonClinicAdmin" 
                          class="table col"
                          :class="isStudentPhysician ? 'col-6' : 'col-3'">
                          <div v-if="!isStudentPhysician  && !isStudentDentist">
                            <p class="head">Course Duration (yrs)</p>
                            <p class="data">
                              {{ data.course_duration }}
                              <span v-if="data.distance_learning == true">
                                (Distance Learning)
                              </span>
                            </p>
                          </div>
                          <div v-else>
                            <p class="head">Course End Date</p>
                            <p class="data">
                              {{ formatDateMonthYear(data.predicted_completion_date) || '--' }}
                            </p>
                          </div>
                        </div>
                        <div v-if="!isNonClinicAdmin && !isStudent" class="table col col-6">
                          <p class="head">Issued By</p>
                          <p class="data">
                            <span v-if="data.other_issue_authority">
                              (others) {{ data.other_issue_authority || "--" }}
                            </span>
                            <span v-else>
                              {{ data.issue_authority_name || "--" }}
                            </span>
                          </p>
                        </div>
                        <div class="table col col-6">
                          <p class="head">
                            Linked Documents
                            <a
                              v-if="!edit_Qualification_id"
                              class="data text-primary"
                              :style="{
                                'font-size': '14px',
                                cursor: 'pointer',
                              }"
                              @click="showDocumentModal(data)"
                              >(Click here to Link)</a
                            >
                          </p>
                          <p
                            class="data"
                            v-if="data.document_ids && data.document_ids.length"
                          >
                            <span
                              ><a
                                class="text-primary"
                                style="cursor: pointer"
                                v-for="(i, index) in data.documents"
                                :key="index"
                                @click="preview(i)"
                                >{{
                                  index == 0
                                    ? i.document_name
                                    : `, ${i.document_name}`
                                }}</a
                              ></span
                            >
                          </p>
                          <p class="data" v-else>No Documents Linked</p>
                        </div>
                        <div v-if="isStudent" class="table col col-6">
                          <p class="head">Is your Course</p>
                          <p class="data">
                            {{ data.full_part_time || '--' }}
                          </p>
                        </div>
                        <div v-if="isStudent" class="table col col-6">
                          <p class="head">Is Distance/Online</p>
                          <p class="data">
                            {{ data.distance_learning == null ? 'N/A' : (data.distance_learning ? 'Distance' : 'Online') }}
                          </p>
                        </div>
                        <div v-if="isStudent" class="table col col-6">
                          <p class="head">Is this course sponsored/funded</p>
                          <p class="data">
                            {{ data.is_sponsored ? "Yes" : "No" || '--'}}
                          </p>
                        </div>
                        <div v-if="isStudent && data.is_sponsored" class="table col col-6">
                            <p class="head">Sposorship Start Date</p>
                            <p class="data">
                              {{ formatDateMonthYear(data.sponsored?.start_date) || '--' }}
                            </p>
                         </div>
                         <div v-if="isStudent && data.is_sponsored" class="table col col-6">
                            <p class="head">Sposorship End Date</p>
                            <p class="data">
                              {{ formatDateMonthYear(data.sponsored?.end_date) || '--' }}
                            </p>
                          </div>
                      </CRow>
                    </div>
                    <div class="hide-small-screen">
                      <div
                        class="
                          widget-content-actions
                          d-flex
                          flex-column
                          justify-content-top
                        "
                      >
                        <CButton
                          class="icon icon-edit mb-2"
                          v-if="data && data.candidate_qualification_id"
                          @click="editQualification(data)"
                        >
                        </CButton>
                        <CButton
                          class="icon"
                          v-if="data && data.candidate_qualification_id"
                          @click="deleteQualification(data)"
                        >
                          <i class="fas fa-trash-alt" />
                        </CButton>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </ValidationObserver>
        </CCardBody>
      </CCard>
      <Modal
        :modalTitle="deleteConfirmModal.modalTitle"
        :modalColor="deleteConfirmModal.modalColor"
        :modalContent="deleteConfirmModal.modalContent"
        :isShowPopup="deleteConfirmModal.isShowPopup"
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
      />
      <document-mapping-modal
        :isShowPopup="linkDocument.isShowPopup"
        :document_type_id="12"
        :label="linkDocument.label"
        :candidate_qualification_id="linkDocument.candidate_qualification_id"
        :document_ids="linkDocument.document_ids"
        @modalCallBack="linkModalCallBack"
      />
      <preview-modal
        modalTitle="Document Preview"
        modalColor="primary"
        :buttons="['Ok']"
        size="lg"
        :isShowPopup="previewModal.isShowPopup"
        :modalCallBack="previewModalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :closeOnBackdrop="false"
      />
    </div>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
import pdf from "vue-pdf";
import ProfileSection from "./ProfileSection";
import Modal from "@/components/reusable/Modal";
import DocumentMappingModal from "./DocumentMappingModal.vue";
import PreviewModal from "../../components/reusable/PreviewModal.vue";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
export default {
  mixins: [ProfileSection],
  props: {
    candidateTypeId: {
      type: Number,
      default: null,
    },
  },
  components: {
    Modal,
    DocumentMappingModal,
    PreviewModal,
  },
  data() {
    return {
      isDisabled: false,
      profile: {
        main_Qualification: false,
        distance_learning: null,
      },
      payload: {},
      candidate_qualification_id: 0,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      deleteMainqualification: false,
      errors: [],
      edit_Qualification_id: null,
      isNewQualification: false,
      _index: null,
      cardHasError: "",
      linkDocument: {
        isShowPopup: false,
        label: "",
        candidate_qualification_id: null,
        document_ids: [],
      },
      previewModal: {
        isShowPopup: false,
        isPreview: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      is_primary: false,
      candidate_sponsor_id: null,
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "getCandidateQualification",
      "candidateProfile",
      "issueAuthorityByQualification",
      "qualificationByCountryAndCandidateType",
      "getCourseQualifications",
      "getSponsoredCandidates",
    ]),
    qualByCtryAndCandiTypeWithoutOther() {
      const res = this.qualificationByCountryAndCandidateType.filter(
        ({ label }) => !label.toLowerCase().includes("other")
      ) || [];
      return res
    },
    options() {
      return {
        main_qualification_id:
          this.qualByCtryAndCandiTypeWithoutOther || [],
        issued_by:
          this.issueAuthorityByQualification({
            // country_id: this.profile?.country_id?.code,
            qualification_id: this.profile?.main_qualification_id?.code,
          }) || [],
        // issued_by: this.getFilteredIssueAuthorityByCandidateTypeAndCountry || [],
        distance_learning: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
          { id: null, label: "N/A" },
        ],
        country: this.locationCountryInfo || [],
        byExam: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        modeOfCourse:[
          { code: 1, label: "Full Time" },
          { code: 2, label: "Part Time" }
        ],
        course_qualifications: this.getCourseQualifications || [],
        distance_online:[
          { code: true, label: "Distance" },
          { code: false, label: "Online" },
          { code: null, label: "N/A" }
        ],
      };
    },
    Qualifications() {
      if (Object.keys(this.candidateProfile).length) {
        this.profile.dob = this.candidateProfile.dob;
      }
      const distance_learning = this.isStudent ? distance_learning == null ? null : distance_learning : 
                                  distance_learning !== null ? distance_learning : "--";
      if (this.getCandidateQualification) {
        return (
          this.getCandidateQualification?.length &&
          this.getCandidateQualification.map(
            ({
              candidate_qualification_id,
              qualification_id,
              qualification_name,
              qualification,
              country_id,
              country_name,
              year_awarded,
              month_awarded,
              issue_authority_id,
              issue_authority_name,
              course_duration,
              distance_learning,
              // qualification: { qualification_with_acronym },
              other_issue_authority,
              by_exam,
              other_qualification_name,
              description,
              document_ids,
              documents,
              primary,
              speciality_name,
              course_start_date,
              predicted_completion_date,
              full_part_time,
              is_sponsored,
              sponsored,
            }) => {
              return {
                candidate_qualification_id:
                  candidate_qualification_id !== null
                    ? candidate_qualification_id
                    : "--",
                qualification_id:
                  qualification_id !== null ? qualification_id : "--",
                qualification_name:
                  qualification_name !== null ? qualification_name : "--",
                qualification: qualification,
                country_id: country_id !== null ? country_id : null,
                country_name: country_name !== null ? country_name : "--",
                year_awarded: year_awarded,
                month_awarded: month_awarded,
                issue_authority_id:
                  issue_authority_id !== null
                    ? issue_authority_id
                    : issue_authority_name === null &&
                      other_issue_authority !== null
                    ? "Others"
                    : null,
                issue_authority_name:
                  issue_authority_name !== null
                    ? issue_authority_name
                    : issue_authority_name === null &&
                      other_issue_authority !== null
                    ? "Others"
                    : null,
                other_issue_authority: other_issue_authority,
                course_duration:
                  course_duration !== null ? course_duration : "--",
                distance_learning: distance_learning,
                qualification_with_acronym:
                  qualification?.qualification_with_acronym !== null
                    ? qualification?.qualification_with_acronym
                    : null,
                by_exam: by_exam !== null ? by_exam : "--",
                other_qualification_name: other_qualification_name,
                description: description !== null ? description : "--",
                document_ids: document_ids,
                documents: documents,
                primary: primary,
                speciality_name,
                course_start_date,
                predicted_completion_date,
                full_part_time,
                is_sponsored,
                sponsored,
              };
            }
          )
        );
      } else {
        return false;
      }
    },
    SortedQualifications() {
      if (this.Qualifications) {
        return this.Qualifications.sort((a, b) => {
          return b.qualification_name - a.qualification_name;
        }).sort((a, b) => {
          return b.year_awarded - a.year_awarded;
        });
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.qualification
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    mainQualificationIsOther() {
      return (
        this.profile?.main_qualification_id?.label?.includes(
          "Other - not listed"
        ) || false
      );
    },
    isNonClinicAdmin() {
      return this.candidateTypeId == 6 ? true : false;
    },
    isPhysician() {
      return this.candidateTypeId === 1 || false;
    },
    isDentist() {
      return this.candidateTypeId === 4 || false;
    },
    isStudent() {
      return this.candidateProfile?.is_student;
    },
    isStudentPhysician() {
      return this.isPhysician && this.isStudent;
    },
    isStudentDentist() {
      return this.isDentist && this.isStudent
    },
    isSponsored() {
      return this.candidateProfile?.is_sponsored;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.fetchQualification();
      }
    },
    "profile.is_sponsored"(newVal) {
      if(newVal === false) {
        this.profile.sponsorship_start_date = null
        this.profile.sponsorship_end_date = null
        this.payload.sponsored = {}
      }
    }
  },
  mounted() {
    this.fetchQualificationByCandidateType();
    if (this.isNonClinicAdmin) {
      this.fetchQualificationByCandidateType({
        candidate_type_id: this.candidateProfile?.candidate_type_id,
      });
    } else this.fetchQualificationByCountryAndCandidateType();
    this.fetchIssueAuthorityByQualification();
    this.fetchSponsoredCandidates(this.candidateProfile?.candidate_uid);
    this.fetchCourse(this.candidateProfile?.candidate_type_id);
  },
  methods: {
    ...mapActions([
      "fetchQualification",
      "uploadQualification",
      "deleteCandidateQualification",
      "showToast",
      "updateQualification",
      "fetchUploadedDocument",
      "fetchQualificationByCandidateType",
      "fetchCourseQualification",
      "fetchSponsoredCandidates",
    ]),
    async updateQualificationInfo() {
      this.tooltipHide();
      const isValid = await this.$refs.qualification.validate();
      this.errors = Object.values(this.$refs.qualification.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.cardHasError = this.errors[0] || "Please fill mandatory fields!";
        return;
      } else if (
        this.profile.main_qualification_id.course_duration_reqd &&
        !this.profile.course_duration
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.cardHasError =
          "Qualification Course Duration can't be less than one year!";
      } else {
        if (this.edit_Qualification_id !== null) {
          let _count = 0;
          _.forEach(
            this.getCandidateQualification?.length
              ? this.getCandidateQualification
              : [],
            (qual, i) => {
              let qual_year = m(this.profile?.qualified_date).year();
              if (
                qual.qualification_id ===
                  this.profile.main_qualification_id.code &&
                m(qual.year_awarded).isSame(qual_year)
              ) {
                this._index = i;
                _count = _count + 1;
              }
            }
          );
          if (
            _count >= 1 &&
            this.getCandidateQualification[this._index]
              .candidate_qualification_id !== this.edit_Qualification_id
          ) {
            this.cardHasError = "Qualification Already Exists!";
          } else {
            this.saveQualification();
          }
        } else {
          if (
            _.findIndex(
              this.getCandidateQualification?.length
                ? this.getCandidateQualification
                : [],
              (qual) => {
                let qual_year = m(this.profile?.qualified_date).year();
                return (
                  qual.qualification_id ===
                    this.profile.main_qualification_id.code &&
                  m(qual.year_awarded).isSame(qual_year)
                );
              }
            ) >= 0
          ) {
            this.cardHasError = "Qualification Already Exists!";
          } else {
            this.saveQualification();
          }
        }
      }
    },
    showQualificationInfo() {
      this.isNewQualification = true;
      this.profile.student_online_distance_learning = null;
    },
    saveQualification() {
      this.payload = {
        country_id:
          this.profile.country_id !== null
            ? this.profile.country_id?.code
            : null,
        qualification_id:
          this.profile.main_qualification_id !== null
            ? this.profile.main_qualification_id?.code
            : null,
        year_awarded:
          this.profile.qualified_date !== null
            ? m(this.profile.qualified_date).year()
            : null,
        month_awarded:
          this.profile.qualified_date !== null
            ? m(this.profile.qualified_date).month() + 1
            : null,
        issue_authority_id:
          this.profile.issued_by !== null
            ? this.profile.issued_by?.code == "Others"
              ? null
              : this.profile.issued_by.code
            : null,
        other_issue_authority:
          this.profile.other_issue_authority !== null &&
          this.profile.issued_by?.code == "Others"
            ? this.profile.other_issue_authority
            : null,
        speciality_id: this.payload.speciality_id,
        sub_speciality_id: this.candidateProfile.sub_speciality_id,
        course_duration: this.profile?.course_duration,
        distance_learning: this.isStudent ? this.profile?.student_online_distance_learning ?? null : this.profile?.distance_learning ?? null,
        by_exam: this.profile.by_exam !== null ? this.profile.by_exam : null,
        other_qualification_name: this.mainQualificationIsOther
          ? this.profile.other_qualification_name
          : null,
        description:
          this.profile.description &&
          this.profile.main_qualification_id?.allow_subject_input
            ? this.profile.description
            : null,
        document_ids: this.profile.document_ids?.length
          ? this.profile.document_ids
          : null,
        full_part_time: this.payload?.full_part_time ?? null,
        course_start_date: this.profile?.course_start_date ?? null,
        predicted_completion_date: this.profile?.predicted_completion_date ?? null,
        is_sponsored: this.profile?.is_sponsored ?? null,
      };
      if(this.payload?.is_sponsored != null) {
        this.payload.sponsored =  {
          candidate_uid: this.candidateProfile?.candidate_uid,
          candidate_sponsor_id: this.profile?.candidate_sponsor_id || null,
          start_date: this.profile?.sponsorship_start_date || null,
          end_date: this.profile?.sponsorship_end_date || null,
        }
      }
      if (this.payload) {
        this.createQualification();
      }
    },
    confirmMain() {
      this.deleteMainqualification = true;
      this.deleteConfirmModal.modalContent =
        "You already have a main qualification. Please delete that to add a new main qualification";
      this.deleteConfirmModal.modalColor = "primary";
      this.deleteConfirmModal.modalTitle = "Confirmation";
      this.deleteConfirmModal.buttons = ["Ok"];
      this.deleteConfirmModal.isShowPopup = true;
    },
    createQualification() {
      let appendAction;
      if (this.edit_Qualification_id == null) {
        this.payload = {
          ...this.payload,
          candidate_uid: this.profile.candidate_uid,
        };
        appendAction = this.uploadQualification(this.payload).then((res) => {
          if (this.payload.document_ids) {
            this.fetchUploadedDocument();
          }
        });
      } else {
        let candidate_qualification_id = this.edit_Qualification_id;
        let qualificationPayload = this.payload;
        appendAction = this.updateQualification({
          candidate_qualification_id,
          qualificationPayload,
        }).then((res) => this.fetchUploadedDocument());
      }
      Promise.all([appendAction]).then((res) => {
        this.discard();
      });
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.qualification.reset();
        this.$refs.qualification.errors.clear;
      });
      this.cardHasError = "";
      this.tooltipHide();
      this.multiFormEdited({ qualification: false });
      this.multiFormEdited({ showError: false });
    },
    deleteQualification(data) {
      if (
        this.getCandidateQualification &&
        this.getCandidateQualification.length === 1
      ) {
        this.tooltipHide();
        (this.deleteConfirmModal.modalTitle = "NOTIFICATION"),
          (this.deleteConfirmModal.buttons = ["OK"]);
        this.deleteConfirmModal.modalContent =
          "Please add another Qualification to delete the selected entry!";
        this.deleteConfirmModal.isShowPopup = true;
      } else if (
        this.getCandidateQualification.filter(({ main }) => main === true)[0]
      ) {
        // let main_id = this.getCandidateQualification.filter(
        //   ({ main }) => main === true
        // )[0].qualification_id;
        // let delete_id = data.qualification_id;
        // if (main_id === delete_id) {
        //   this.deleteConfirmModal.modalContent =
        //     "Are you sure to delete this main qualification? This will reset Speciality and Sub Speciality!";
        // } else {
        this.deleteConfirmModal.modalContent = `Are you sure you want to delete this qualification - ${
          data?.qualification_name != "Other - not listed"
            ? data?.qualification_name
            : data?.other_qualification_name
        } ?`;
        // }
        this.tooltipHide();
        this.candidate_qualification_id = data.candidate_qualification_id;
        this.candidate_sponsor_id = data.sponsored?.candidate_sponsor_id || null;
        this.deleteConfirmModal.buttons = ["Cancel", "Confirm"];
        this.deleteConfirmModal.isShowPopup = true;
      }
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        if (this.deleteMainqualification === true) {
          this.deleteMainqualification = false;
        } else {
          this.deleteCandidateQualification({
            candidate_qualification_id: this.candidate_qualification_id,
            candidate_sponsor_id: this.candidate_sponsor_id});
        }
      }
      this.candidate_qualification_id = "";
      this.discard();
      this.deleteConfirmModal.isShowPopup = false;
    },
    editQualification(data) {
      let el = this.$el.getElementsByClassName("qualificationInfo")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      const full_part_time_data = this.fullPartTimeData(data.full_part_time) || null;
      this.profile = {
        ...this.profile,
        country_id: {
          code: data?.country_id,
          label: data?.country_name !== "--" ? data?.country_name : null,
        },
        main_qualification_id: {
          code: data?.qualification_id,
          label: data?.qualification_with_acronym || null,
          by_exam: data?.qualification?.by_exam,
          allow_subject_input: data?.qualification?.allow_subject_input,
        },
        qualified_date:
          data?.year_awarded && data?.month_awarded != "--"
            ? data?.month_awarded < 10
              ? `${data?.year_awarded}-0${data?.month_awarded}`
              : `${data?.year_awarded}-${data?.month_awarded}`
            : null,
        issued_by: {
          code:
            data?.issue_authority_id !== "Others"
              ? data?.issue_authority_id
              : "Others",
          label:
            data?.issue_authority_name !== "Others"
              ? data?.issue_authority_name
              : "Others - not listed",
        },
        other_issue_authority: data?.other_issue_authority,
        course_duration:
          data?.course_duration !== "--" ? data?.course_duration : null,
        distance_learning:
          data?.distance_learning !== "--" ? data?.distance_learning : null,
        by_exam: data.by_exam !== "--" ? data?.by_exam : null,
        other_qualification_name: data?.other_qualification_name,
        description: data?.description !== "--" ? data?.description : null,
        document_ids: data?.document_ids,
        primary: data?.primary,
        full_part_time: this.fullPartTimeData(data),
        speciality_id: {
          code: data.speciality_id,
          label: data.speciality_name,
        },
        course_start_date: data.course_start_date,
        predicted_completion_date: data.predicted_completion_date,
        is_sponsored: data.is_sponsored,
        sponsorship_start_date: data.sponsored?.start_date,
        sponsorship_end_date: data.sponsored?.end_date,
        candidate_sponsor_id: data.sponsored?.candidate_sponsor_id,
      };
      this.handleChangeRadio('full_part_time',full_part_time_data)
      this.handleChangeRadio('student_online_distance_learning',{ code: data.distance_learning, label: data.distance_learning ? 'Yes' : 'No' })
      this.is_primary = data?.primary;
      this.edit_Qualification_id = data?.candidate_qualification_id;
      if (this.isNonClinicAdmin) {
        this.fetchQualificationByCandidateType({
          candidate_type_id: this.candidateProfile?.candidate_type_id,
        });
      } else {
        this.fetchQualificationByCountryAndCandidateType({
          country_id: data?.country_id,
          candidate_type_id: this.candidateProfile?.candidate_type_id,
        });
      }
      this.fetchIssueAuthorityByQualification({
        qualification_id: data?.qualification_id,
      });
    },
    handleSlider: debounce(function (value) {
      Vue.set(this.profile, "course_duration", value);
      Vue.set(this.payload, "course_duration", value);
      this.multiFormEdited["qualification"] = true;
    }, 500),
    tooltipHide() {
      this.$emit("tooltipHide");
    },
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
    discard() {
      this.profile = {};
      this.profile.distance_learning = null;
      this.edit_Qualification_id = null;
      this.clearFields();
      this.isNewQualification = false;

      if (this.isNonClinicAdmin) {
        this.fetchQualificationByCandidateType({
          candidate_type_id: this.candidateProfile?.candidate_type_id,
        });
      } else {
        this.fetchQualificationByCountryAndCandidateType();
      }
      this.fetchIssueAuthorityByQualification();
    },
    showLinkDocument() {
      this.linkDocument.candidate_qualification_id = this.edit_Qualification_id
        ? this.edit_Qualification_id
        : null;
      this.linkDocument.document_ids = this.profile.document_ids || [];
      this.linkDocument.label = this.profile.main_qualification_id.label;
      this.linkDocument.isShowPopup = true;
    },
    showDocumentModal(data) {
      this.linkDocument.candidate_qualification_id =
        data.candidate_qualification_id
          ? data.candidate_qualification_id
          : null;
      this.linkDocument.document_ids = data.document_ids || [];
      this.linkDocument.label = data?.qualification_with_acronym;
      this.linkDocument.isShowPopup = true;
    },
    linkModalCallBack(action, value, candidate_qual_id) {
      if (action == true) {
        let qualificationPayload = {
          document_ids: value?.length ? value : null,
        };
        if (!candidate_qual_id) {
          this.profile.document_ids = value;
        } else {
          this.profile.document_ids = value;
          this.updateQualification({
            candidate_qualification_id: candidate_qual_id,
            qualificationPayload,
          }).then((res) => {
            this.fetchQualification();
            this.fetchUploadedDocument();
          });
        }
      }
      this.linkDocument.candidate_qual_id = null;
      this.linkDocument.isShowPopup = false;
    },
    showPreview(data) {
      const { uuid_link_to_doc } = data;
      //  const _baseUrl = BASE_URL;
      //  const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      const _base_domain = "https://" + window.location.host;
      return appendAccessToken(`${_base_domain}${uuid_link_to_doc}`);
    },
    preview(data) {
      data.link_to_document = this.showPreview(data);
      let document_ext = data.document_ext?.document_ext;
      if (
        document_ext === "PNG" ||
        document_ext === "JPG" ||
        document_ext === "JPEG" ||
        document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          data.link_to_document
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        document_ext === "TXT" ||
        document_ext === "EPS" ||
        document_ext === "TIF" ||
        document_ext === "TIFF" ||
        document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    fetchCourse(id) {
      if ([1, 4].includes(id)) {
        this.fetchCourseQualification({
          candidate_type_id: id,
          qualification_classification: 'First Degree'});
      }
      else if([2, 3].includes(id)) {
        this.fetchCourseQualification({
          candidate_type_id: id,
          qualification_classification: ['First Degree','Diploma']});
      }
    },
    fullPartTimeData(full_part_time) {
      const selectedMode = this.options.modeOfCourse.filter(
        (mode) => mode.label === full_part_time
      );
      if (selectedMode.length) {
        const val =  {
          code: selectedMode[0].code,
          label: selectedMode[0].label,
        };
        return val
      }
      return
    },
    formatDateMonthYear(date) {
      return date ? m(date).format("DD-MMM-YYYY") : "--";
    },
  },
  filters: {
    qualificationName(data) {
      if (data !== null)
        return data?.qualification_name?.includes("Other - not listed")
          ? `Other${
              data?.other_qualification_name
                ? " - " + data?.other_qualification_name
                : ""
            }`
          : data?.qualification_with_acronym || "--";
      return "--";
    },
  },
};
</script>
